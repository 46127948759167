// src/store/authActions.js
import { jwtDecode } from 'jwt-decode';

import axios from '../api/axiosConfig';
import { login, logout } from './authSlice';
import { showSnackbar } from './snackbarSlice';

// Login action
export const loginUser = (username, password) => async (dispatch) => {
  try {
    const response = await axios.post('/auth/login', { username, password });
    const { token } = response.data; // Assume the token is in the response
    const user = jwtDecode(token); // Decode the token to get user data
    dispatch(login(user));
    dispatch(showSnackbar({ message: 'Login successful!', severity: 'success' }));
    return true;
  } catch (error) {
    dispatch(
      showSnackbar({ message: 'Login failed. Please check your credentials.', severity: 'error' })
    );
    console.error('Login failed', error);
    return false;
  }
};

// Logout action
export const logoutUser = () => async (dispatch) => {
  try {
    await axios.post('/auth/logout');
    dispatch(logout());
    dispatch(showSnackbar({ message: 'Logout successful!', severity: 'success' }));
  } catch (error) {
    dispatch(showSnackbar({ message: 'Logout failed. Please try again.', severity: 'error' }));
    console.error('Logout failed', error);
  }
};
