import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import DashboardLayout from '../layouts/dashboard';

const IndexPage = lazy(() => import('../pages/app'));
const UserPage = lazy(() => import('../pages/user'));
const LoginPage = lazy(() => import('../pages/Login'));
const PeoplePage = lazy(() => import('../pages/PeoplePage'));
const AssetsPage = lazy(() => import('../pages/AssetsPage'));
const Page404 = lazy(() => import('../pages/page-not-found'));
const CompanyPage = lazy(() => import('../pages/CompanyPage'));
const LocationsPage = lazy(() => import('../pages/LocationsPage'));
const DepartmentPage = lazy(() => import('../pages/DepartmentPage'));
const CategoryPage = lazy(() => import('../pages/CategoryPage'));
const AssetForm = lazy(() => import('../components/CompanyAssets/AssetForm'));
const AssetDetailPage = lazy(() => import('../components/CompanyAssets/AssetDetailsPage'));
const UserActivityTimeline = lazy(() => import('../components/User/UserActivityTimeline'));
const ReportingPage = lazy(() => import('../pages/ReportingPage')); // Add the new page

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: (
        <PrivateRoute>
          <DashboardLayout>
            <Suspense fallback={<div>Loading...</div>}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </PrivateRoute>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: '/user-activities/:userId', element: <UserActivityTimeline /> },
        { path: 'assets/view-asset/:id', element: <AssetDetailPage /> },
        { path: 'assets/:id/edit', element: <AssetForm /> },
        { path: 'departments', element: <DepartmentPage /> },
        { path: 'locations', element: <LocationsPage /> },
        { path: 'categories', element: <CategoryPage /> },
        { path: 'asset-list', element: <AssetsPage /> },
        { path: 'companies', element: <CompanyPage /> },
        { path: 'assets/new', element: <AssetForm /> },
        { path: 'people', element: <PeoplePage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'reporting', element: <ReportingPage /> }, // Add the new route
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '404',
      element: <Page404 />,
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
