import axios from 'axios';

import store from '../store';
import { logout } from '../store/authSlice';
import { showSnackbar } from '../store/snackbarSlice';

const instance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:3000/api',
  withCredentials: true,
});

instance.interceptors.request.use((config) => config);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error('Error response:', error.response);
    } else {
      console.error('Error without response:', error);
    }
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      store.dispatch(
        showSnackbar({ message: 'Session expired. Please log in again.', severity: 'warning' })
      );
      store.dispatch(logout());
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;
